import {getOsintSources, getOsintFilterValues, getOsintSourcesAggregatedByCountry} from "@/services/osint-sources";

import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "@/assets/css/multiselect.scss";
import MultiAccordian from "../../components/multilevel-accordian";

export default {
    name: "neo-osint-sources",
    props: [],
    components: {
        Multiselect,
        MultiAccordian,
    },
    data() {
        return {
            osintFilterCountry: {label: "All", value: "All"},
            osintFilterRegion: {label: "All", value: "All"},
            osintFilterTag: {label: "All", value: "All"},
            osintFilterType: {label: "All", value: "All"},
            osintFilteredValues: {children: []},
            allOsintSources: [],
            osintData: {
                children: [],
            },
            filteredTagData: {children: []},
            OsintSourcesFilterValues: {region: [], country: [], type: [], tags: []},
            tabs: ["region", "tag"],
            currentTab: "region",
            tabScroll: 1,
        };
    },
    async mounted() {
        this.$refs.accordcontainer.style.height = `${this.$refs.accordcontainer.offsetHeight}px`;
        this.allOsintSources = this.$store.getters.getOsintData;

        this.OsintSourcesFilterValues = await getOsintFilterValues(this.allOsintSources);

        this.osintData = await getOsintSourcesAggregatedByCountry(this.allOsintSources);

        this.filterOsintSource();
        this.filterOsintTag();
        this.$refs.accordcontainer.addEventListener("scroll", (e) => {
            if (this.$refs.accordcontainer.scrollTop > this.$refs.accordcontainer.scrollHeight - this.$refs.accordcontainer.offsetHeight - 100) {
                this.tabScroll = this.tabScroll + 1;
            }
        });
    },
    computed: {
        getCurrentTabData() {
            let itemCount = 20;
            let data = {children: []};
            if (this.currentTab === "tag") {
                data.children = this.filteredTagData.children;
            } else {
                data.children = this.osintFilteredValues.children;
            }
            return {children: data.children.slice(0, itemCount * this.tabScroll)};
        },
    },
    methods: {
        onTabChange(tab) {
            this.currentTab = tab;
            this.tabScroll = 1;
        },
        titleCase(s) {
            let title = "";
            s = s.split("-");
            s[0] = s[0][0].toUpperCase() + s[0].slice(1);
            s.forEach((e) => (title = title + e + " "));
            return title;
        },

        filterOsintSource(filter) {
            if (filter === "region") {
                var countries = [];
                this.allOsintSources.map((e) => {
                    if (e.region && e.region.includes(this.osintFilterRegion.value)) {
                        if (e.country) {
                            countries = countries.concat(e.country);
                        }
                    }
                });

                let Countries = [...new Set(["All", ...countries])].map((e) => {
                    return {label: this.titleCase(e), value: e};
                });

                this.OsintSourcesFilterValues.country = Countries;
            }

            let values = [];

            if (this.osintFilterRegion.value === "All" && this.osintFilterCountry.value === "All" && this.osintFilterType.value === "All") {
                values = this.allOsintSources;
            } else {
                for (let e of this.allOsintSources) {
                    if (this.osintFilterRegion.value) {
                        if (!(e.region && (this.osintFilterRegion.value === "All" ? true : e.region.includes(this.osintFilterRegion.value)))) continue;
                    }

                    if (this.osintFilterCountry.value) {
                        if (!(e.country && (this.osintFilterCountry.value === "All" ? true : e.country.includes(this.osintFilterCountry.value)))) continue;
                    }

                    if (this.osintFilterType.value) {
                        if (!(e.type && (this.osintFilterType.value === "All" ? true : e.type.includes(this.osintFilterType.value)))) continue;
                    }

                    values.push(e);
                }
            }

            let data = {children: []};

            let filterkey = "country";
            let filterValue = this.osintFilterCountry.value;

            if (this.osintFilterRegion.value != "All" && this.osintFilterCountry.value != "All" && this.osintFilterType.value != "All") {
                filterkey = "country";
                filterValue = this.osintFilterType.value;
            }

            // if(this.osintFilterType === "All"
            // &&this.osintFilterRegion !="All"
            // && this.osintFilterCountry !="All" ){
            //   filterKey = 'country'
            //   filterValue = this.osintFilterCountry
            // }

            values.forEach((source) => {
                source[filterkey] &&
                    source[filterkey].forEach((e) => {
                        let index = data.children.findIndex((val) => val.text.toLowerCase() === e);
                        if (index >= 0) {
                            data.children[index].children.push({text: source.source, badges: source.type});
                        } else {
                            data.children.push({text: e, children: [{text: source.source, badges: source.type}]});
                        }
                    });
            });

            data.children.forEach((e) => {
                let badges = [];
                for (var c = 0; c < e.children.length; c++) {
                    e.children[c].badges.forEach((b) => {
                        if (!badges.includes(b)) badges.push(b);
                    });
                }
                e.badges = badges;
            });

            this.osintFilteredValues = data;
        },

        filterOsintTag() {
            let data = [];
            if (this.osintFilterTag.value === "All") {
                data = {children: []};
                this.allOsintSources.forEach((source) => {
                    source.tags &&
                        source.tags.forEach((e) => {
                            let index = data.children.findIndex((val) => val.text.toLowerCase() === e.toLowerCase());
                            if (index >= 0) {
                                data.children[index].children.push({text: source.source});
                            } else {
                                data.children.push({text: e, children: [{text: source.source}]});
                            }
                        });
                });
            } else {
                data = {children: [{text: this.osintFilterTag.value, children: []}]};
                let sources = this.allOsintSources.filter((e) => e.tags && e.tags.includes(this.osintFilterTag.value));

                sources.forEach((source) => {
                    data.children[0].children.push({text: source.source});
                });
            }
            this.filteredTagData = data;
        },

        getOsintSourcesFilterValues() {
            let country = [];
            let region = [];
            let type = [];
            this.allOsintSources.forEach((e) => {
                if (e.original_source === "mind_map" || e.original_source === "id_occrp") {
                    if (e.region) {
                        region = region.concat(e.region);
                    }
                    if (e.country) {
                        country = country.concat(e.country);
                    }
                    if (e.type) {
                        type = type.concat(e.type);
                    }
                }
            });

            let Countries = [...new Set(["All", ...country])].map((e) => {
                return {label: e.replaceAll("-", " ")[0].toUpperCase() + e.slice(1), value: e};
            });

            let Region = [...new Set(["All", ...region])].map((e) => {
                return {label: e.replaceAll("-", " ")[0].toUpperCase() + e.slice(1), value: e};
            });

            let Type = [...new Set(["All", ...type])].map((e) => {
                return {label: e.replaceAll("-", " ")[0].toUpperCase() + e.slice(1), value: e};
            });

            this.OsintSourcesFilterValues = {country: Countries, region: Region, type: Type};
        },
    },
};
