import MultilevelAccordion from "vue-multilevel-accordion";
import Badge from "@/components/badge";

export default {
    name: "neo-multi-accordian",
    components: {MultilevelAccordion, "neo-badge": Badge},
    props: ["items"],
    data() {
        return {
            selectedRow: "",
        };
    },
    computed: {},
    filters: {
        capitalize: function (value) {
            if (!value) return "";
            return value
                .split("_")
                .map((val) => {
                    return val.charAt(0).toUpperCase() + val.slice(1);
                })
                .join(" ");
        },
        removeHttp: function (value) {
            if (!value) return "";
            let spiltArr = value.split("//")[1].split("www.");
            return spiltArr.length > 1 ? spiltArr[1] : spiltArr[0];
        },
    },
    mounted() {},
    methods: {
        getBadges() {},
        exapnd() {
            let height = 0;
            if (document.querySelector(".open")) {
                [...document.querySelector(".open").parentNode.parentNode.querySelectorAll("li")].forEach((e) => (height = height + e.offsetHeight));
                document.querySelector(".open").parentNode.parentNode.querySelector(".panel").style.height = height;
            }
        },
        setSelected(rowValue) {
            // alert(rowValue)
            this.selectedRow = rowValue;
        },
    },
};
